import { create } from 'zustand';

export const usePathStore = create((set) => ({
	pcsoTab: 'PCSO Results Today',
	setPcsoTab: (state) => set(() => ({ pcsoTab: state })),
}));

export const useSort = create((set) => ({
	sort: false,
	setSort: (state) => set(() => ({ sort: state })),
}));

export const useSwipe = create((set) => ({
	swipe: 0,
	setSwipe: (state) => set(() => ({ swipe: state })),
}));
