import { serverApi } from './api';

const API = '/api/v1';

export const getStlLinkApi = () => serverApi.get(`${API}/stl-nav`).then((res) => res.data);

export const getStlCityApi = ({ gameName, location }) =>
	serverApi
		.get(`${API}/stl-result?gameName=${gameName}&location=${location}`)
		.then((res) => res.data);

export const getStlLocationNameApi = ({ gameName }) =>
	serverApi.get(`${API}/stl-result?gameName=${gameName}`).then((res) => res.data);

export const getStlTodayApi = (date) =>
	serverApi.get(`${API}/stl-result?date=${date}`).then((res) => res.data);

export const getStlGameTypeApi = ({ gameName }) =>
	serverApi
		.get(`${API}/stl-game-type?gameName=${gameName}&isSTL=true&sort=false`)
		.then((res) => res.data);

