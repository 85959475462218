import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode(
          [
            '#FFFFFF',
            '#FFFFFF',
            '#FFFFFF',
            '#FFFFFF',
            // '#F5F5F5',
            // '#F5F5F5',
            // '#F5F5F5',
          ],
          ["#000000", "#000000", "#000000", "#000000", "#222222"]
        )(props),
      },
    }),
  },
  components: {
    Drawer: {
      baseStyle: (props) => ({
        dialog: {
          bg: mode("white", "#464646")(props),
        },
      }),
    },
    Select: {
      baseStyle: (props) => ({
        icon: {
          right: 0,
          width: "fit-content",
          padding: 0,
          margin: 0,
        },
        // field: (props) => ({ padding: 0, margin: 0}),
      }),
    },
  },
  colors: {
    purple: { 200: "#492C85", 500: "#492C85" },
    blue: { 500: "#003098", 900: "#" },
    green: { 500: "#31D94A", 200: "#31D94A" },
  },
  breakpoints: {
    sm: "340px",
    sm2: "700px",
    md: "960px",
    lg: "1024px",
    xl: "1440px",
    "2xl": "1920px",
    "3xl": "2560px",
  },
  fonts: {
    heading: `'Roboto', sans-serif`,
    body: `'Roboto', sans-serif`,
  },
  fontSizes: {
    "3xs": 8,
  },
  fontWeight: {},
});

export default theme;
