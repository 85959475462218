import { Box, Divider, Flex, useMediaQuery } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { React } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getStlLinkApi } from '../api/stlApi';
import Footer from '../components/Footer';
import LocationPopover from '../components/stlResult/LocationPopover';
import StlTopBar from '../components/stlResult/StlTopBar';
import StlReuseResult from '../components/stlResult/StlReuseResult';
import StlTodayResult from '../components/stlResult/StlTodayResult';

const StlResultScreen = ({ children }) => {
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	const { siteName, gameName } = useParams();
	const getStlLink = useQuery(['getStlLinkApi'], getStlLinkApi);

	return (
		<>
			{isLargerThan1024 ? (
				<>
					<Flex backgroundColor='blue.500' height='56px'>
						<Flex color='white' justifyContent='center' maxWidth='1920px' width='100%'>
							<Flex
								color={siteName === 'stlResult' && gameName === undefined ? '#FCDF09' : 'white'}
								fontSize={['', '', '', '', '18', '20px', '20px']}
								fontWeight='600'
								paddingX={['', '', '', '3%', '', '5%', '5%']}
								justifyContent='center'
								alignItems='center'
								onClick={() => {}}>
								<Link to='/stlResult'>STL Result Today</Link>
							</Flex>
							{getStlLink.isSuccess &&
								getStlLink.data?.map((item) => {
									return (
										<Flex
											key={item.name}
											backgroundColor='blue.500'
											height='56px'
											justifyContent='center'
											alignItems='center'
											fontSize={['', '', '', '', '18', '20px', '20px']}
											fontWeight='600'
											paddingX={['', '', '', '3%', '', '5%', '5%']}>
											<LocationPopover label={item.name} item={item} location={item.location} />
										</Flex>
									);
								})}
						</Flex>
					</Flex>
					{children}
					<Box width='90%' marginLeft='5%'>
						<Divider borderWidth='1px' />
					</Box>
					<Footer />
				</>
			) : (
				<>
					<StlTopBar />
					{gameName === '' || gameName === undefined ? <StlTodayResult /> : <StlReuseResult />}
				</>
			)}
		</>
	);
};

export default StlResultScreen;
