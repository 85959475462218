import { serverApi } from './api';

const API = '/api/v1';

export const getResultPerGameApi = ({ gameType, sort, isSTL }) =>
	serverApi
		.get(`${API}/game-type?gameName=${gameType}&isSTL=${isSTL}&&sort=${sort}`)
		.then((res) => res.data);

export const getFirstPathApi = (path) =>
	serverApi.get(`${API}/check-path-one?path=${path}`).then((res) => res.data);

export const getSecondPathApi = (secPath) =>
	serverApi.get(`${API}/check-path-two?secPath=${secPath}`).then((res) => res.data);

export const getThirdPathApi = ({ secPath, trdPath }) =>
	serverApi
		.get(`${API}/check-path-three?gameName=${secPath}&location=${trdPath}`)
		.then((res) => res.data);

export const getServerTimeApi = () => serverApi.get(`${API}/server-time`).then((res) => res.data);
