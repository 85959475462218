import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'swiper/css/bundle';
import App from './App';
import Error from './components/Error';
import DualPathsScreen from './screens/DualPathsScreen';
import UniPathScreen from './screens/UniPathScreen';
import theme from './utils/theme';
import TriPathScreen from './screens/TriPathScreen';

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		errorElement: <Error />,
		children: [
			{
				path: '/:siteName',
				element: <UniPathScreen />,
				errorElement: <Error />,
			},
			{
				path: '/:siteName/:gameName',
				element: <DualPathsScreen />,
				errorElement: <Error />,
			},
			{
				path: '/:siteName/:gameName/:locationName',
				element: <TriPathScreen />,
				errorElement: <Error />,
			},
		],
	},
]);
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<QueryClientProvider client={queryClient}>
		<ChakraProvider theme={theme}>
			<RouterProvider router={router} />
		</ChakraProvider>
	</QueryClientProvider>,
);
