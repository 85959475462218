import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import NumText from '../../utils/NumText';
import ResultCard from '../ResultCard';

const PcsoReuseResult = ({ index, data }) => {
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	const imageWidth = '50%';

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Flex
				flexDirection='row'
				justifyContent='space-between'
				width='100%'
				color={'blue.500'}
				fontSize='14px'
				fontWeight={600}
				display={isLargerThan1024 ? 'none' : 'flex'}>
				<Text>{data?.[index]?.mainData?.[0]?.name}</Text>
				<Text>{data?.[index]?.mainData?.[0]?.date}</Text>
			</Flex>

			{data?.[index]?.mainData?.map((items, idx) => {
				return (
					<Box justifyContent='center' alignContent='center' key={idx}>
						<ResultCard
							width={['300px', '330px', '450px', '450px', '835px']}
							height={['139px', '139px', '200px', '200px', '375px']}
							imageWidth={imageWidth}
							numberWidth='100%'
							backgroundColor={isLargerThan1024 ? '#ECECEC' : '#EDF3FF'}
							imageBgColor={isLargerThan1024 ? '#F5F5F5' : '#FFFFFF'}
							src={items.image}
							time={items.time}
							winner={items.winner}
							marginBottom={['24px', '24px', '24px', '24px', '52px']}
							hasDate={true}
							fontSizeWinner={['12px', '12px', '14px', '14px', '20px']}
							borderIsBlue={isLargerThan1024 ? false : true}>
							{items.resultNum?.map((item, index) => {
								return (
									<Flex
										width={['30px', '32px', '45px', '45px', '88px']}
										height={['30px', '32px', '45px', '45px', '88px']}
										margin='auto'
										marginBottom={['2%', '1%', '2%', '5%']}
										borderRadius={180}
										alignItems='center'
										justifyContent='center'
										backgroundColor='blue.500'
										key={index}>
										<NumText fontSize={['16px', '16px', '18px', '18px', '34px']}>
											{item.number}
										</NumText>
									</Flex>
								);
							})}
						</ResultCard>
					</Box>
				);
			})}
		</Flex>
	);
};

export default PcsoReuseResult;
