import {
	Box,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Portal,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const LocationPopover = ({ item, label, location }) => {
	const navigate = useNavigate();
	const { onOpen, onClose, isOpen } = useDisclosure();
	const { gameName, locationName } = useParams();

	return (
		<>
			<Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
				<PopoverTrigger>
					<Box
						color={gameName?.toLowerCase() === item.link ? '#FCDF09' : 'white'}
						cursor={'pointer'}>
						{label}
					</Box>
				</PopoverTrigger>
				<Portal>
					<PopoverContent backgroundColor='darkBlue' width='100%' padding='10px'>
						<PopoverBody>
							{location.map((data, index) => {
								return (
									<Box
										cursor='pointer'
										onClick={() => {
											onClose();
											if (gameName === item.link && locationName === data.locationName) {
												return null;
											} else {
												navigate(`/stlResult/${item.link}/${data.locationName}`);
											}
										}}
										key={index}>
										<Text
											color={
												gameName?.toLowerCase() === item.link && locationName === data.locationName
													? '#FCDF09'
													: 'white'
											}
											textAlign='center'
											paddingX='20px'
											paddingY='5px'
											_hover={{
												borderBottom: '1px',
												color: '#FCDF09',
												borderColor: '#FCDF09',
											}}>
											{data.locationName === 'All' ? 'All Location' : data.locationName}
										</Text>
									</Box>
								);
							})}
						</PopoverBody>
					</PopoverContent>
				</Portal>
			</Popover>
		</>
	);
};

export default LocationPopover;
