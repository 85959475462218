import {
	Box,
	Flex,
	Image,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import NumText from '../utils/NumText';

const HeaderText = ({ children, ...props }) => {
	const textColor = '#FFFFFF';
	return (
		<Text color={textColor} {...props} fontSize='14px' fontWeight='600' cursor='default'>
			{children}
		</Text>
	);
};
const BodyText = ({ children, ...props }) => {
	const textColor = '#000000';
	return (
		<Text color={textColor} {...props} fontSize='14px' fontWeight='600' cursor='default'>
			{children}
		</Text>
	);
};

const Result30Days = ({ data, gameName }) => {
	const [order, setOrder] = useState(false);
	const [sortData, setSortData] = useState([]);
	const sorting = (col) => {
		if (order) {
			const sorted = [...sortData].sort((a, b) => a.id - b.id);

			setSortData(sorted);
			setOrder(!order);
		} else {
			const sorted = [...sortData].sort((a, b) => b.id - a.id);

			setSortData(sorted);
			setOrder(!order);
		}
	};

	useEffect(() => {
		setSortData(data);
	}, [data]);

	return (
		<Flex
			alignItems='center'
			justifyContent='center'
			flexDirection='column'
			width='100%'
			paddingBottom={14}
			display={['none', 'none', 'none', 'none', 'flex']}
			paddingX={5}>
			<Box width='100%' maxWidth='1440px'>
				<Text
					textAlign='start'
					fontSize='14px'
					fontWeight='500'
					marginBottom='20px'
					cursor='default'>
					{gameName} Lotto result history for the last 30 draws:
				</Text>
			</Box>
			<TableContainer
				maxWidth='1440px'
				width='100%'
				display={['none', 'none', 'none', 'none', 'block']}>
				<Table width='100%'>
					<Thead backgroundColor='blue.500'>
						<Tr backgroundColor='blue.500'>
							<Td width='18%' onClick={() => sorting(sortData.id)}>
								<Flex>
									<HeaderText width='70%'>Draw Date</HeaderText>
									<Image src={order ? '/assets/svg/1to9.svg' : '/assets/svg/9to1.svg'} />
								</Flex>
							</Td>
							<Td width='15%'>
								<HeaderText width='70%'>Lotto Game</HeaderText>
							</Td>
							<Td width='32%'>
								<HeaderText width='30%'>Winning Number</HeaderText>
							</Td>
							<Td width='20%'>
								<HeaderText width='30%'>Jackpots</HeaderText>
							</Td>
							<Td width='15%'>
								<HeaderText width='30%'>Winners</HeaderText>
							</Td>
						</Tr>
					</Thead>
					<Tbody>
						{data.length > 0 ? (
							sortData?.map((item, index) => {
								return (
									<Tr
										key={index}
										_odd={{ backgroundColor: '#FFFFFF' }}
										_even={{ backgroundColor: '#F5F5F5' }}>
										<Td width='22%'>
											<Flex>
												<BodyText textAlign='left' paddingRight={3}>
													{item.date}
												</BodyText>
												<BodyText>{item.time}</BodyText>
											</Flex>
										</Td>
										<Td width='15%'>
											<BodyText width='70%'>{item.name}</BodyText>
										</Td>
										<Td width='32%'>
											<Flex gap='8px'>
												{item.resultNum.map((num, index) => {
													return (
														<Flex
															width={['30px', '30px', '45px', '45px', '43px', '48px']}
															height={['30px', '30px', '45px', '45px', '43px', '48px']}
															borderRadius={180}
															alignItems='center'
															justifyContent='center'
															backgroundColor='blue.500'
															key={index}>
															<NumText fontSize={['16px', '16px', '18px', '22px']}>
																{num.number}
															</NumText>
														</Flex>
													);
												})}
											</Flex>
										</Td>
										<Td width='18%'>
											<BodyText width='30%'>{item.jackpot}</BodyText>
										</Td>
										<Td width='13%'>
											<BodyText width='30%'>{item.winner}</BodyText>
										</Td>
									</Tr>
								);
							})
						) : (
							<Tr justifyContent='center'>
								<Td colSpan={5}>
									<Text
										fontSize={['16px', '16px', '18px', '22px']}
										textAlign='center'
										color='#000000'>
										No data
									</Text>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
		</Flex>
	);
};

export default Result30Days;
