import { serverApi } from './api';

const API = '/api/v1';

export const getPcsoLinkApi = () => serverApi.get(`${API}/pcso-nav`).then((res) => res.data);

export const getPcsoTodayResultApi = (date) =>
	serverApi.get(`${API}/pcso-result?date=${date}`).then((res) => res.data);

export const getMobilePcsoGameApi = () =>
	serverApi.get(`${API}/mobilePcso/game-type`).then((res) => res.data);
