import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const ContactUsScreen = () => {
	return (
		<>
			<Box maxWidth='1440px' marginX='auto' paddingBottom={20} paddingX={5}>
				<Text
					fontWeight='600'
					fontSize='32px'
					color='blue.500'
					paddingTop='34px'
					paddingBottom='52px'
					cursor='default'>
					Contact Us
				</Text>
			</Box>
		</>
	);
};

export default ContactUsScreen;
