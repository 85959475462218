import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../utils/ImgComp';

const ResultCard = ({
	title,
	height,
	width,
	backgroundColor,
	imageBgColor,
	date,
	time,
	children,
	winner,
	numberWidth,
	src,
	marginBottom,
	hasDate = false,
	displayDate = ['none', 'none', 'none', 'none', 'block'],
	isToday = false,
	titleToday,
	displayToday = ['block', 'block', 'block', 'none'],
	fontSizeWinner = ['12px', '12px', '14px', '14px', '14px'],
	borderIsBlue = false,
}) => {
	return (
		<Box width={width} alignSelf='center' marginBottom={marginBottom}>
			{isToday ? (
				<Text
					width={['300px', '330px', '450px', '649px']}
					display={displayToday}
					fontWeight='500'
					cursor='default'
					fontSize={['14px', '14px', '14px', '20px']}
					color='#000000'
					textAlign='start'>
					{titleToday}
				</Text>
			) : null}

			{hasDate && (
				<Flex
					justifyContent='space-between'
					display={['flex', 'flex', 'flex', 'flex', 'none']}
					marginTop={4}>
					<Text fontSize='14px' fontWeight='500' color='#000000' cursor='default' textAlign='start'>
						{title}
					</Text>
					<Text fontSize='16px' fontWeight='600' color='blue.500' cursor='default'>
						{date}
					</Text>
				</Flex>
			)}

			<Flex
				flexDirection='row'
				width={width}
				height={height}
				backgroundColor={backgroundColor}
				borderRadius={8}
				borderWidth={2}
				borderColor={borderIsBlue ? '#0051FF' : '#CACACA'}>
				<Flex
					alignItems='center'
					justifyContent='center'
					backgroundColor={imageBgColor}
					borderRightRadius={180}
					borderLeftRadius={8}
					padding='21px'
					width='40%'>
					<Box>
						<ImgComp pngName={src} gameType={title} />
					</Box>
				</Flex>

				<Flex
					flexDirection='column'
					justifyContent='space-between'
					width='100%'
					paddingX='8px'
					paddingY={['8px', '8px', '8px', '8px', '12px']}>
					<Box>
						<Text
							align='end'
							width='100%'
							fontWeight='300'
							fontSize={['16px', '16px', '24px']}
							fontStyle='italic'
							color='#3E3E3E'
							display={displayDate}
							cursor='default'>
							{date}
						</Text>
						<Text
							align='end'
							width='100%'
							fontWeight='700'
							fontSize={['15px', '15px', '20px']}
							fontStyle='italic'
							cursor='default'
							color='#003098'>
							{time}
						</Text>
					</Box>
					<Flex width='100%' justifyContent='center'>
						<Flex
							width={numberWidth}
							flexDirection='row'
							alignItems='flex-end'
							justifyContent='space-evenly'>
							{children}
						</Flex>
					</Flex>
					<Box>
						<Text
							align='end'
							width='100%'
							fontWeight='400'
							fontSize={fontSizeWinner}
							color='#888888'
							fontStyle='italic'
							cursor='default'>
							{winner} winners
						</Text>
					</Box>
				</Flex>
			</Flex>
		</Box>
	);
};

export default ResultCard;
