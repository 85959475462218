import { Box, Divider, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import DatePicker from 'react-datepicker';
import { useOutletContext } from 'react-router-dom';
import { getPcsoTodayResultApi } from '../../api/pcsoApi';
import Loading from '../../utils/Loading';
import NumText from '../../utils/NumText';
import ResultCard from '../ResultCard';
const TextTitleMobile = ({ children, ...props }) => {
	return (
		<Text
			width={['300px', '330px', '450px', '649.18px']}
			display={['block', 'block', 'block', 'none']}
			fontSize={['14px', '14px', '14px', '20px']}
			cursor='default'
			textAlign='left'
			fontWeight='500'
			{...props}>
			{children}
		</Text>
	);
};

const PcsoTodayResult = () => {
	// - api
	const [date, setDate, maxDate] = useOutletContext();
	const getPcsoTodayResult = useQuery(['getPcsoTodayResultApi', date], () =>
		getPcsoTodayResultApi(dayjs(date).format('YYYY-MM-DD')),
	);
	const imageWidth = '50%';

	return (
		<Box maxWidth='1440px' marginX='auto' paddingBottom={20}>
			{getPcsoTodayResult.isLoading ? (
				<Box marginY='120px' justifyContent='center' alignSelf='center'>
					<Loading />
				</Box>
			) : (
				<>
					<Flex alignItems='center' flexDirection='column'>
						<Flex
							flexDirection='row'
							justifyContent='space-between'
							alignItems='center'
							width={['300px', '330px', '450px', '649px', '649px', '100%']}>
							<Text
								fontWeight='600'
								fontSize='32px'
								color='blue.500'
								paddingTop='34px'
								paddingBottom='52px'
								// paddingX={[0, 0, 0, 0, 0, 2]}
								display={['none', 'none', 'none', 'none', 'block']}
								cursor='default'>
								PCSO Result Today {dayjs(date).format('DD/MM/YYYY')}
							</Text>
							<Box
								paddingBottom={5}
								// paddingRight={[0, 0, 0, 0, 0, 5]}
								display={['none', 'none', 'none', 'none', 'block']}
								cursor='pointer'>
								<DatePicker
									zIndex={2000}
									selected={date}
									minDate={new Date('03/08/2023')}
									maxDate={new Date(maxDate)}
									onChange={(date) => setDate(date)}
									customInput={
										<Image src='/assets/svg/calendarWeb.svg' width='52px' height='52px' />
									}
								/>
							</Box>
						</Flex>

						<TextTitleMobile>Ez 2</TextTitleMobile>
						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(2, 1fr)',
								'repeat(2, 1fr)',
							]}
							alignItems='center'
							justifyContent='center'
							gap={['16px', '16px', '16px', '16px', '48px']}
							paddingBottom={[5, 5, 5, 5, 0]}>
							{getPcsoTodayResult?.data?.twoD.map((items, index) => {
								return (
									<Flex flexDirection='column' key={index}>
										<ResultCard
											width={['300px', '330px', '450px', '649px', '649px', '100%']}
											height={['139px', '139px', '200px', '252px']}
											imageWidth={imageWidth}
											numberWidth={['70%', '60%', '50%', '50%']}
											backgroundColor='#F5F5F5'
											imageBgColor='#FFFFFF'
											src={items.image}
											title={items.title}
											date={items.date}
											time={items.time}
											winner={items.winner}
											displayDate='block'
											isToday={true}
											titleToday='Ez 2'
											displayToday={['none', 'none', 'none', 'block']}>
											{items.resultNum.map((item, index) => {
												return (
													<Flex
														width={['45px', '45px', '45px', '74px']}
														height={['45px', '45px', '45px', '74px']}
														margin='auto'
														borderRadius={180}
														alignItems='center'
														justifyContent='center'
														backgroundColor='blue.500'
														key={index}>
														<NumText fontSize={['18px', '18px', '18px', '24px']}>
															{item.number}
														</NumText>
													</Flex>
												);
											})}
										</ResultCard>
									</Flex>
								);
							})}
						</Grid>
						<Divider
							marginY={5}
							borderWidth={1.5}
							display={['none', 'none', 'none', 'none', 'block']}
						/>
						<TextTitleMobile>Suertres</TextTitleMobile>
						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(2, 1fr)',
								'repeat(2, 1fr)',
							]}
							alignItems='center'
							justifyContent='center'
							gap={['16px', '16px', '16px', '16px', '48px']}
							paddingBottom={[5, 5, 5, 5, 0]}>
							{getPcsoTodayResult?.data?.threeD.map((items, index) => {
								return (
									<Flex flexDirection='column' key={index}>
										<ResultCard
											width={['300px', '330px', '450px', '649px', '649px', '100%']}
											height={['139px', '139px', '200px', '252px']}
											imageWidth={imageWidth}
											numberWidth={['80%']}
											backgroundColor='#F5F5F5'
											imageBgColor='#FFFFFF'
											src={items.image}
											title={items.title}
											date={items.date}
											time={items.time}
											winner={items.winner}
											displayDate='block'
											isToday={true}
											titleToday='Suertres'
											displayToday={['none', 'none', 'none', 'block']}>
											{items.resultNum.map((item, index) => {
												return (
													<Flex
														width={['45px', '45px', '45px', '74px']}
														height={['45px', '45px', '45px', '74px']}
														margin='auto'
														borderRadius={180}
														alignItems='center'
														justifyContent='center'
														backgroundColor='blue.500'
														key={index}>
														<NumText fontSize={['18px', '18px', '18px', '24px']}>
															{item.number}
														</NumText>
													</Flex>
												);
											})}
										</ResultCard>
									</Flex>
								);
							})}
						</Grid>
						<Divider
							marginY={5}
							borderWidth={1.5}
							display={['none', 'none', 'none', 'none', 'block']}
						/>
						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(2, 1fr)',
								'repeat(2, 1fr)',
							]}
							alignItems='center'
							justifyContent='center'
							gap={['16px', '16px', '16px', '16px', '48px']}>
							{getPcsoTodayResult?.data?.other.map((items, index) => {
								return (
									<Flex flexDirection='column' key={index}>
										<ResultCard
											width={['300px', '330px', '450px', '649px', '649px', '100%']}
											height={['139px', '139px', '200px', '252px']}
											imageWidth={imageWidth}
											numberWidth={'95%'}
											backgroundColor='#F5F5F5'
											imageBgColor='#FFFFFF'
											src={items.image}
											title={items.title}
											date={items.date}
											time={items.time}
											winner={items.winner}
											displayDate='block'
											displayToday='block'
											isToday={true}
											titleToday={items.title}>
											{items.resultNum.map((item, index) => {
												return (
													<Flex
														width={
															items.title === '4 Digits'
																? ['40px', '40px', '40px', '74px']
																: ['30px', '32px', '45px', '60px']
														}
														height={
															items.title === '4 Digits'
																? ['40px', '40px', '40px', '74px']
																: ['30px', '32px', '45px', '60px']
														}
														margin='auto'
														borderRadius={180}
														alignItems='center'
														justifyContent='center'
														backgroundColor='blue.500'
														key={index}>
														<NumText fontSize={['16px', '16px', '18px', '24px']}>
															{item.number}
														</NumText>
													</Flex>
												);
											})}
										</ResultCard>
									</Flex>
								);
							})}
						</Grid>
					</Flex>
				</>
			)}
		</Box>
	);
};

export default PcsoTodayResult;
