import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../../styles.css';

// import required modules
import { Box, Flex } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import SwiperCore, { Pagination } from 'swiper';
import { getMobilePcsoGameApi, getPcsoLinkApi } from '../../api/pcsoApi';
import { useSwipe } from '../../hooks/useStore';
import Result30DaysMobile from '../Result30DaysMobile';
import PcsoReuseResult from './PcsoReuseResult';
import PcsoTodayResult from './PcsoTodayResult';

SwiperCore.use([Pagination]);

const PcsoSwiper = () => {
	const swiperRef = useRef(null);
	const [data, setData] = useState([]);
	const [marginTop, setMarginTop] = useState('10vh');
	const setSwipe = useSwipe((state) => state.setSwipe);
	const getPcsoLink = useQuery(['getPcsoLinkApi'], getPcsoLinkApi);
	const getMobilePcsoGame = useQuery(['getMobilePcsoGameApi'], getMobilePcsoGameApi);
	window.onload = () => {
		checkPaginationAlignment();
		window.addEventListener('resize', checkPaginationAlignment);
		return () => {
			// Clean up the event listener when the component unmounts
			window.removeEventListener('resize', checkPaginationAlignment);
		};
	};
	function checkPaginationAlignment() {
		var paginationContainer = document.getElementById('pagination-container');
		if (!paginationContainer) {
			// Pagination container doesn't exist yet, return
			return;
		}

		var paginationBullets = document.getElementsByClassName('swiper-pagination-bullet');
		if (paginationBullets.length === 0) {
			// No pagination bullets exist yet, return
			return;
		}
		var containerWidth = window.innerWidth;
		var bulletWidth = 0;
		Object.values(paginationBullets).forEach((v) => (bulletWidth += v.offsetWidth + 14));
		var bulletsPerLineTwo = containerWidth < bulletWidth;
		var bulletsPerLineThree = containerWidth < bulletWidth / 2;
		var bulletsPerLineHeight = containerWidth < bulletWidth - 45;

		if (bulletsPerLineTwo) {
			document.getElementsByClassName('swiper-pagination')[0].classList.add('align-left');
		} else {
			document.getElementsByClassName('swiper-pagination')[0].classList.remove('align-left');
		}

		if (bulletsPerLineThree) {
			document.getElementsByClassName('swiper-pagination')[0].classList.add('margin-top-eighteen');
			document.getElementsByClassName('swiper-pagination')[0].classList.remove('margin-top-ten');
			setMarginTop('20vh');
		} else if (bulletsPerLineHeight) {
			document.getElementsByClassName('swiper-pagination')[0].classList.add('margin-top-ten');
			document
				.getElementsByClassName('swiper-pagination')[0]
				.classList.remove('margin-top-eighteen');
			setMarginTop('12vh');
		} else {
			document.getElementsByClassName('swiper-pagination')[0].classList.remove('margin-top-ten');
			document
				.getElementsByClassName('swiper-pagination')[0]
				.classList.remove('margin-top-eighteen');
			setMarginTop('10vh');
		}
	}

	// Call checkPaginationAlignment after the component has rendered
	useEffect(() => {
		checkPaginationAlignment();
		window.addEventListener('resize', checkPaginationAlignment);
		return () => {
			// Clean up the event listener when the component unmounts
			window.removeEventListener('resize', checkPaginationAlignment);
		};
	}, [data]);
	useEffect(() => {
		// Retrieve the stored index from sessionStorage
		const storedIndex = Number(sessionStorage.getItem('swiperIndex'));

		if (storedIndex && swiperRef.current) {
			// Set the initial slide based on the stored index
			swiperRef.current.swiper.slideTo(Number(sessionStorage.getItem('swiperIndex')));
		}
	}, [data, swiperRef.current]);

	const pagination = {
		clickable: true,
		renderBullet: function (index) {
			return `
      <div class="swiper-pagination-bullet swiper-pagination-bullet-active" id="pagination-container" >
        <div class="container" ><p style="">${getPcsoLink.data?.[index]?.name}</p></div>
      </div>`;
		},
	};

	const indexHandler = (item) => {
		sessionStorage.setItem('swiperIndex', item.realIndex);
		setSwipe(item.realIndex);
	};

	useEffect(() => {
		if (getMobilePcsoGame.isSuccess) {
			setData(getMobilePcsoGame.data);
		}
	}, [getMobilePcsoGame.isSuccess]);

	return (
		<>
			<Swiper
				ref={swiperRef}
				pagination={pagination}
				modules={[Pagination]}
				loop={true}
				id='swiperId'
				className='mySwiper'
				onSlideChange={(swiper) => {
					indexHandler(swiper);
					window.scrollTo(0, 0);
				}}
				initialSlide={Number(sessionStorage.getItem('swiperIndex'))}
				slidesPerView={1}
				autoHeight={true}
				// visibilityFullFit={true}
			>
				<SwiperSlide>
					<Box width='100%' marginTop={marginTop} marginBottom='3%'>
						<PcsoTodayResult />
					</Box>
				</SwiperSlide>
				{data.map((items, index) => {
					return (
						<SwiperSlide key={index}>
							<Flex
								width='100%'
								marginTop={marginTop}
								marginBottom='3%'
								alignItems='center'
								flexDirection='column'>
								<PcsoReuseResult index={index} data={data} />
								<Result30DaysMobile
									data={items.tableData}
									gameName={items.mainData?.[0]?.[0]?.name}
								/>
							</Flex>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	);
};

export default PcsoSwiper;
