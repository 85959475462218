import axios from 'axios';

export const serverAxiosParams = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
};

// Create axios instance with default params
export const severAxiosInstance = axios.create(serverAxiosParams);

// Main api function
const api = (axiosInstance) => {
  return {
    get: (url, config) => axiosInstance.get(url, config),
    delete: (url, config) => axiosInstance.delete(url, config),
    post: (url, body, config) => axiosInstance.post(url, body, config),
    put: (url, body, config) => axiosInstance.put(url, body, config),
    patch: (url, body, config) => axiosInstance.patch(url, body, config),
  };
};

// export const localApi = api(localAxiosInstance);
export const serverApi = api(severAxiosInstance);
