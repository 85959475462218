import { Box, Divider, Flex, Grid, GridItem, Select, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getStlLinkApi, getStlLocationNameApi } from '../../api/stlApi';

const StlTopBar = () => {
	const { siteName, gameName, locationName } = useParams();
	const navigate = useNavigate();
	const getStlLink = useQuery(['getStlLinkApi'], getStlLinkApi);
	const getStlLocationName = useQuery(['getStlLocationNameApi', gameName], () =>
		getStlLocationNameApi({ gameName: gameName }),
	);

	return (
		<>
			<Flex flexDirection='column' position=' sticky' top={14} backgroundColor='white' zIndex={99}>
				{getStlLink.isSuccess && (
					<Grid
						// marginX='auto'
						width='100%'
						templateColumns='repeat(2, 1fr)'
						gap={getStlLink.data?.length < 4 ? 3 : 2}
						alignContent='center'
						marginTop='12px'
						paddingX="10px"
						paddingTop={getStlLink.data?.length < 4 ? '0px' : '55px'}
						height={getStlLink.data?.length < 4 ? '15vh' : '15vh'}
						overflowY={getStlLink.data?.length < 4 ? 'hidden' : 'scroll'}
						backgroundColor='white'
						zIndex={999}>
						<GridItem>
							<Box
								borderColor='darkBlue'
								borderRadius={8}
								borderWidth='1px'
								padding='8px'
								color={gameName === undefined ? 'white' : 'black'}
								backgroundColor={gameName === undefined ? 'darkblue' : 'white'}
								fontSize={['13px', '14px', '', '', '', '12px', '16px']}
								fontWeight='600'
								_hover={{
									color: 'white',
									backgroundColor: 'darkBlue',
								}}
								justifyContent='center'
								alignItems='center'
								onClick={() => {
									navigate('/stlResult');
								}}>
								<Text textAlign='center' cursor='pointer'>
									STL Result Today
								</Text>
							</Box>
						</GridItem>

						{getStlLink.data?.map((item, index) => {
							return (
								<GridItem key={index}>
									<Box
										borderColor='darkBlue'
										borderRadius={8}
										borderWidth='1px'
										padding='8px'
										color={gameName === `${item.link}` ? 'white' : 'black'}
										backgroundColor={gameName === `${item.link}` ? 'darkblue' : 'white'}
										fontSize={['13px', '14px', '', '', '', '12px', '16px']}
										fontWeight='600'
										_hover={{
											color: 'white',
											backgroundColor: 'darkBlue',
										}}
										justifyContent='center'
										alignItems='center'
										onClick={() => {
											sessionStorage.setItem('smallNav', `/${item.link}`);
											navigate(`/stlResult/${item.link}`);
										}}>
										<Text textAlign='center' cursor={'pointer'}>
											{item.name}
										</Text>
									</Box>
								</GridItem>
							);
						})}
					</Grid>
				)}
				<Box marginY={2} paddingX={3}>
					<Divider borderWidth='1px' />
				</Box>
				{gameName !== undefined ? (
					<Box paddingX={6}>
						{getStlLocationName.isSuccess && (
							<Select
								marginX='auto'
								width='98%'
								marginY='10px'
								color='blue.500'
								borderColor='blue.500'
								fontWeight='600'
								fontSize='14px'
								textAlign='center'
								value={locationName}
								cursor='pointer'
								onChange={(event) => {
									if (locationName !== event.target.value) {
										navigate(`/${siteName}/${gameName}/${event.target.value}`);
									}
								}}>
								<option value='All'>All Location</option>
								{getStlLocationName.data.map((item, index) => {
									return (
										<option value={item.locationName} key={index}>
											{item.locationName}
										</option>
									);
								})}
							</Select>
						)}
					</Box>
				) : null}
			</Flex>
			<Outlet></Outlet>
		</>
	);
};

export default StlTopBar;
