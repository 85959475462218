import React from "react";
import Lottie from "react-lottie-player";
import lottieJson from "../json/loading.json";
import { Box, Flex } from "@chakra-ui/react";

const Loading = ({ width, height }) => {
  return (
    <Flex
      width='100%'
      height='100%'
      alignItems='center'
      justifyContent='center'
      marginY={10}
    >
      <Box width={100} height={100}>
        <Lottie animationData={lottieJson} play loop />
      </Box>
    </Flex>
  );
};

export default Loading;
