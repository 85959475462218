import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirstPathApi, getSecondPathApi, getThirdPathApi } from '../api/publicApi';
import StlResultScreen from './StlResultScreen';
import StlReuseResult from '../components/stlResult/StlReuseResult';
import Error from '../components/Error';

const TriPathScreen = () => {
	const { siteName, gameName, locationName } = useParams('');
	const [component, setComponent] = useState();
	const getFirstPath = useQuery([`getFirstPathApi`, siteName], () => getFirstPathApi(siteName));
	const getSecondPath = useQuery([`getSecondPathApi`, gameName], () => getSecondPathApi(gameName));
	const getThirdPath = useQuery([`getThirdPathApi`, gameName, locationName], () =>
		getThirdPathApi({ secPath: gameName, trdPath: locationName }),
	);

	useEffect(() => {
		if (getFirstPath.isSuccess && getSecondPath.isSuccess && getThirdPath.isSuccess) {
			if (
				getFirstPath.data?.isstl === true &&
				getSecondPath.data?.message === true &&
				getSecondPath.data?.istoday === false &&
				getThirdPath.data?.hasLocation === true
			) {
				setComponent(
					<StlResultScreen>
						<StlReuseResult />
					</StlResultScreen>,
				);
			} else {
				setComponent(<Error />);
			}
		}
	}, [
		getFirstPath.isSuccess,
		getSecondPath.isSuccess,
		getThirdPath.isSuccess,
		siteName,
		gameName,
		locationName,
	]);

	return <>{component}</>;
};

export default TriPathScreen;
