import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
	const navigate = useNavigate();
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	return (
		<Box
			justifyContent='center'
			alignItems='center'
			backgroundColor='blue.500'
			height='100vh'
			textAlign='center'
			paddingTop={['25vh', '30vh']}
			backgroundImage={isLargerThan1024 ? "url('/assets/images/404.png')" : null}
			backgroundPosition='center'
			backgroundRepeat='no-repeat'
			bgGradient={isLargerThan1024 ? null : 'linear(to-b, blue.500, blue.200)'}>
			<Text textAlign='center' fontSize={['28px', '', '', '50px']} fontWeight='bold' color='white'>
				This page is unavailable.
			</Text>
			<Box
				onClick={() => {
					sessionStorage.setItem('navLink', 'pcsoResult');
					navigate('/pcsoResult');
				}}>
				<Text
					textAlign='center'
					fontSize={['18px', '', '', '20px']}
					color='white'
					textDecoration='underline'>
					Back to Home Page.
				</Text>
			</Box>
		</Box>
	);
};

export default Error;
