import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirstPathApi } from '../api/publicApi';
import Error from '../components/Error';
import StlTodayResult from '../components/stlResult/StlTodayResult';
import ContactUsScreen from './ContactUsScreen';
import FaqScreen from './FaqScreen';
import PcsoResultScreen from './PcsoResultScreen';
import StlResultScreen from './StlResultScreen';

const UniPathScreen = () => {
	//& refresh reset the tab
	// window.onload = () => {
	// 	sessionStorage.setItem('swiperIndex', 0);
	// };

	const [component, setComponent] = useState();
	const { siteName } = useParams();

	const getFirstPath = useQuery([`getFirstPathApi`, siteName], () => getFirstPathApi(siteName));

	useEffect(() => {
		if (getFirstPath.isSuccess) {
			if (getFirstPath.data?.ispcso === true) {
				setComponent(<PcsoResultScreen />);
			} else if (getFirstPath.data?.isstl === true) {
				setComponent(
					<StlResultScreen>
						<StlTodayResult />
					</StlResultScreen>,
				);
			} else if (siteName === 'contactUs') {
				setComponent(<ContactUsScreen />);
			} else if (siteName === 'faqs') {
				setComponent(<FaqScreen />);
			} else {
				setComponent(<Error />);
			}
		}
	}, [getFirstPath.isSuccess, siteName]);

	return <>{component}</>;
};

export default UniPathScreen;
