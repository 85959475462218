import { useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MobileNavbar from './components/MobileNavbar';
import WebNavbar from './components/WebNavbar';

import { useQuery } from '@tanstack/react-query';
import 'swiper/css/bundle';
import { getServerTimeApi } from './api/publicApi';

const App = () => {
	const navigate = useNavigate();
	const [date, setDate] = useState(new Date());
	const [maxDate, setMaxDate] = useState(new Date());
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	const { siteName } = useParams();
	const getServerTime = useQuery(['getServerTimeApi'], getServerTimeApi);

	useEffect(() => {
		if (siteName === '' || siteName === undefined) {
			navigate('/pcsoResult');
		}
	}, [siteName]);

	useEffect(() => {
		if (getServerTime.isSuccess) {
			setMaxDate(new Date(getServerTime.data?.currTime));
			setDate(new Date(getServerTime.data?.currTime));
		}
	}, [getServerTime.isSuccess]);

	return (
		<>
			{isLargerThan1024 ? (
				<WebNavbar date={date} setDate={setDate} maxDate={maxDate} />
			) : (
				<MobileNavbar date={date} setDate={setDate} maxDate={maxDate} />
			)}
		</>
	);
};
export default App;
