import { Text } from '@chakra-ui/react';

const NumText = ({ children, ...props }) => {
  return (
    <Text fontWeight="700" color="#FFFFFF" {...props} cursor="default">
      {children}
    </Text>
  );
};

export default NumText;
