import {
	Box,
	Divider,
	Flex,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { isTablet } from 'react-device-detect';
import { getMobilePcsoGameApi, getPcsoLinkApi } from '../api/pcsoApi';
import Footer from '../components/Footer';
import Result30Days from '../components/Result30Days';
import PcsoReuseResult from '../components/pcsoResult/PcsoReuseResult';
import PcsoSwiper from '../components/pcsoResult/PcsoSwiper';
import PcsoTodayResult from '../components/pcsoResult/PcsoTodayResult';
import { usePathStore } from '../hooks/useStore';

const PcsoResultScreen = () => {
	//reset localstorage
	const [resetLocalStorage, setResetLocalStorage] = useState(false);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		if (resetLocalStorage) {
			// sessionStorage.setItem('swiperIndex', 0);
			setResetLocalStorage(false);
		}
	}, [resetLocalStorage]);

	function handleBeforeUnload() {
		setResetLocalStorage(true);
	}

	//& state
	const pcsoTab = usePathStore((state) => state.pcsoTab);
	const setPcsoTab = usePathStore((state) => state.setPcsoTab);

	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	const [data, setData] = useState();
	const getMobilePcsoGame = useQuery(['getMobilePcsoGameApi'], getMobilePcsoGameApi);
	useEffect(() => {
		if (getMobilePcsoGame.isSuccess) {
			setData(getMobilePcsoGame.data);
		}
	}, [getMobilePcsoGame.isSuccess]);

	const getPcsoLink = useQuery(['getPcsoLinkApi'], getPcsoLinkApi);
	const [swiperId, setSwiperId] = useState(sessionStorage.getItem('swiperIndex'));

	const handleChangeindex = (id) => {
		sessionStorage.setItem('swiperIndex', id);
		setSwiperId(id);
	};

	// useEffect(() => {
	// 	setPcsoTab('PCSO Results Today');
	// 	sessionStorage.setItem('swiperIndex', 0);
	// }, [sessionStorage.getItem('swiperIndex')]);

	return (
		<>
			{isLargerThan1024 ? (
				<>
					<Box
						backgroundColor='blue.500'
						height='56px'
						width='100%'
						alignItems='center'
						color='white'
						justifyContent='space-between'
						// paddingX={['', '', '', '1%', '', '5%', '6%']}
						marginX='auto'>
						<Tabs
							variant='unstyled'
							maxWidth='1920px'
							marginX='auto'
							defaultIndex={Number(sessionStorage.getItem('swiperIndex'))}>
							<TabList justifyContent='space-between' alignItems='center' height='56px'>
								{getPcsoLink.isSuccess &&
									getPcsoLink.data?.map((item) => {
										return (
											<Tab
												minW='80px'
												key={item.label}
												color={Number(swiperId) === item.id ? '#FCDF09' : 'white'}
												fontSize={['', '', '', '', '15px', '20px', '20px']}
												fontWeight={['normal', '', '', 'bold', '', '']}
												_hover={{
													color: isTablet ? 'none' : '#FCDF09',
													borderBottom: isTablet ? '0px' : '1px',
													borderColor: isTablet ? 'none' : '#FCDF09',
												}}
												justifyContent='center'
												alignItems='center'
												cursor={'pointer'}
												onClick={() => {
													sessionStorage.setItem('swiperIndex', item.id);
													handleChangeindex(item.id);
													if (item.label === 'PCSO Results Today') {
														setPcsoTab('PCSO Results Today');
													} else {
														setPcsoTab(item.label);
													}
												}}
												borderWidth={0}
												height='100%'>
												{item.label}
											</Tab>
										);
									})}
							</TabList>

							<TabPanels>
								<TabPanel>
									<PcsoTodayResult />
								</TabPanel>

								{data?.map((items, index) => {
									return (
										<TabPanel key={index}>
											<Flex flexDirection='column'>
												<Text
													marginX='auto'
													fontWeight='600'
													fontSize='32px'
													color='blue.500'
													paddingTop='34px'
													paddingBottom='52px'
													// paddingX={[0, 0, 0, 0, 0, 5]}
													width={['300px', '330px', '450px', '450px', '835px']}
													display={['none', 'none', 'none', 'none', 'block']}
													cursor='default'>
													{items?.mainData?.[0]?.name}
												</Text>
												<PcsoReuseResult index={index} data={data} />
											</Flex>

											<Box display={['none', 'none', 'none', 'none', 'block']}>
												<Result30Days
													data={items?.tableData ?? []}
													gameName={pcsoTab}
													hasTime={false}
												/>
											</Box>
										</TabPanel>
									);
								})}
							</TabPanels>
						</Tabs>
						<Divider borderWidth='1px' />
						<Footer />
					</Box>
				</>
			) : (
				<PcsoSwiper />
			)}
		</>
	);
};

export default PcsoResultScreen;
