import { HamburgerIcon } from '@chakra-ui/icons';
import {
	Box,
	Divider,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Icon,
	Image,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { React, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import navlink from '../constant/link';
import { useSwipe } from '../hooks/useStore';
import Footer from './Footer';
import { getServerTimeApi } from '../api/publicApi';
import { useQuery } from '@tanstack/react-query';

const MobileNavbar = ({ date, setDate, maxDate }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = useRef();
	const setSwipe = useSwipe((state) => state.setSwipe);

	const swipe = useSwipe((state) => state.swipe);

	const navigate = useNavigate();
	const { siteName, gameName } = useParams();
	const getServerTime = useQuery(['getServerTimeApi'], getServerTimeApi);
	const handleNavigate = (item) => {
		sessionStorage.setItem('navLink', item.name);
		sessionStorage.setItem('stlResult', 'All');
		if (siteName !== item.link) {
			navigate(`/${item.link}`);
		}
	};

	return (
		<>
			<Flex
				justifyContent='space-between'
				alignItems='center'
				padding={['5px', '10px', '10px', '10px', '20px', '', '']}
				position=' sticky'
				top={0}
				height={'8vh'}
				backgroundColor='white'
				zIndex={1000}>
				<Icon
					as={HamburgerIcon}
					ref={btnRef}
					onClick={onOpen}
					width='30px'
					height='30px'
					margin='5px'
					cursor='pointer'
				/>
				<Text textAlign='center' fontSize='26px' fontWeight='bold' cursor='default'>
					PANALO 29
				</Text>
				<Flex
					justifyContent='space-between'
					width={['50px', '', '60px', '70px', '', '', '']}
					cursor='pointer'>
					{(siteName === 'pcsoResult' && swipe === 0) ||
					(siteName === 'stlResult' && (gameName === '' || gameName === undefined)) ? (
						<DatePicker
							zIndex={2000}
							selected={date}
							minDate={new Date('03/08/2023')}
							maxDate={new Date(maxDate)}
							onChange={(date) => setDate(date)}
							customInput={<Image src={'/assets/svg/calendar.svg'} />}
						/>
					) : null}
				</Flex>
			</Flex>
			<Drawer isOpen={isOpen} placement='left' onClose={onClose} finalFocusRef={btnRef} size={'md'}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton
						justifyContent='center'
						marginTop={2}
						right='0px'
						left='20px'
						color='white'
						size='lg'
					/>
					<DrawerHeader
						bgGradient='linear(to-r, blue.500, blue.200)'
						color='white'
						justifyContent='center'
						textAlign='center'
						fontSize='26px'
						fontWeight='bold'>
						PANALO 29
					</DrawerHeader>

					<DrawerBody backgroundColor='#f5f5f5'>
						{navlink.map((item) => {
							return (
								<Link
									key={item.label}
									to={`${item.link}`}
									onClick={() => {
										onClose();
										handleNavigate(item);
										setSwipe(0);
										setDate(new Date(getServerTime.data?.currTime));
									}}>
									<Flex
										justifyContent='flex-start'
										alignItems='center'
										width='100%'
										paddingY={['3%', '3%', '', '', '3%', '5%', '']}>
										<Image src={`/assets/svg/${item.icon}.svg`} />

										<Text
											fontSize={['18px']}
											fontWeight='600'
											marginX={['10%']}
											color={siteName === item.name ? 'darkblue' : 'black'}
											_hover={{ color: 'darkBlue', fontWeight: '800' }}
											cursor={'pointer'}>
											{item.label}
										</Text>
									</Flex>
								</Link>
							);
						})}
						<Box backgroundColor='#f5f5f5' marginY={5}>
							<Divider borderWidth='1px' />
							<Box marginY={5}>
								<Footer />
							</Box>
						</Box>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<Box>
				<Outlet context={[date, setDate, maxDate]}></Outlet>
			</Box>
		</>
	);
};

export default MobileNavbar;
