import React, { useEffect, useState } from 'react';
import { getFirstPathApi, getSecondPathApi } from '../api/publicApi';
import { useNavigate, useParams } from 'react-router-dom';
import Error from '../components/Error';
import { useQuery } from '@tanstack/react-query';
import StlReuseResult from '../components/stlResult/StlReuseResult';
import StlResultScreen from './StlResultScreen';

const DualPathsScreen = () => {
	const navigate = useNavigate();
	const { siteName, gameName } = useParams('');
	const [component, setComponent] = useState();
	const getFirstPath = useQuery([`getFirstPathApi`, siteName], () => getFirstPathApi(siteName));
	const getSecondPath = useQuery([`getSecondPathApi`, gameName], () => getSecondPathApi(gameName));

	useEffect(() => {
		if (getFirstPath.isSuccess && getSecondPath.isSuccess) {
			if (
				getFirstPath.data?.isstl === true &&
				getSecondPath.data?.message === true &&
				getSecondPath.data?.istoday === false
			) {
				navigate(`/${siteName}/${gameName}/all`);
			} else {
				setComponent(<Error />);
			}
		}
	}, [getFirstPath.isSuccess, getSecondPath.isSuccess, siteName, gameName]);

	return <>{component}</>;
};

export default DualPathsScreen;
