import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import NumText from '../utils/NumText';

const Result30DaysMobile = ({ gameName, data, is6D = true }) => {
	return (
		<Flex
			flexDirection='column'
			paddingX={['11px']}
			paddingBottom={['8%']}
			display={['flex', 'flex', 'flex', 'flex', 'none']}
			justifyContent='center'
			alignSelf='center'>
			<Box width='100%' maxWidth='1440px'>
				<Text
					textAlign='start'
					fontWeight='500'
					fontSize='10px'
					color='#000000'
					marginBottom={['8px', '8px', '8px', '8px', '20px']}
					cursor='default'>
					{gameName} Lotto result history for the last 30 draws:
				</Text>
			</Box>

			{data?.map((item, index) => {
				return (
					<Flex
						borderRadius='8px'
						borderWidth={1}
						flexDirection='column'
						width={['300px', '330px', '450px', '450px']}
						_odd={{
							backgroundColor: '#ffffff',
							borderColor: '#e5e5e5',
							borderWidth: '2px',
							marginY: '8px',
						}}
						_even={{
							backgroundColor: '#f5f5f5',
							borderColor: '#e5e5e5',
							borderWidth: '2px',
						}}
						justifyItems='center'
						key={index}>
						<Flex padding='8px 11px 21px 11px' flexDirection='column' gap='16px'>
							<Flex gap={1} justifyContent='flex-end'>
								<Text
									textAlign='right'
									fontWeight='400'
									fontSize='12px'
									color='#000000'
									cursor='default'>
									{item.day}
								</Text>
								<Text
									textAlign='right'
									fontWeight='500'
									fontSize='12px'
									color='blue.500'
									cursor='default'>
									{item.date}&nbsp;&nbsp;{item.time}
								</Text>
							</Flex>

							<Flex justifyContent='center' alignItems='center'>
								<Flex
									flexDirection='row'
									justifyContent='space-evenly'
									alignItems='center'
									width='80%'>
									{item.resultNum.map((num, index) => {
										return (
											<Flex
												width={is6D ? ['30px', '35px', '45px'] : '45px'}
												height={is6D ? ['30px', '35px', '45px'] : '45px'}
												// margin='auto'
												borderRadius={180}
												alignItems='center'
												justifyContent='center'
												backgroundColor='blue.500'
												key={index}>
												<NumText fontSize={is6D ? '16px' : '18px'}>{num.number}</NumText>
											</Flex>
										);
									})}
								</Flex>
							</Flex>
						</Flex>
						<Divider />
						<Flex flexDirection='column' padding='6px 13px 8px 13px'>
							<Flex width='100%'>
								<Text
									width='30%'
									fontWeight='600'
									fontSize={['10px', '10px', '15px']}
									cursor='default'>
									Jackpots
								</Text>
								<Text
									width='70%'
									fontWeight='500'
									fontSize={['10px', '10px', '15px']}
									cursor='default'>
									{item.jackpot}
								</Text>
							</Flex>
							<Flex>
								<Text
									width='30%'
									fontWeight='600'
									fontSize={['10px', '10px', '15px']}
									cursor='default'>
									Winners
								</Text>
								<Text
									width='70%'
									fontWeight='500'
									fontSize={['10px', '10px', '15px']}
									cursor='default'>
									{item.winner}
								</Text>
							</Flex>
						</Flex>
					</Flex>
				);
			})}
		</Flex>
	);
};

export default Result30DaysMobile;
