import { Box, Flex, Image, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
const Footer = () => {
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

	return (
		<>
			<Flex
				flexDirection={isLargerThan1024 ? 'row' : 'column'}
				backgroundImage={isLargerThan1024 ? null : "url('/assets/images/phi-republika1.png')"}
				backgroundPosition='center'
				backgroundRepeat='no-repeat'
				padding={isLargerThan1024 ? '2px' : '5px'}
				paddingY={isLargerThan1024 ? '50px' : '0px'}
				maxWidth='1920px'
				marginX='auto'
				bgSize='280px 280px'
				justifyContent='space-between'
				position='relative'
				top='auto'
				bottom='0'
				width='100%'
				cursor='default'
				color='#595959'>
				{isLargerThan1024 ? (
					<Flex justifySelf='center'>
						<Image
							src='/assets/svg/phi-republika.svg'
							width={['', '', '', '', '200px', '250px', '']}
							height={['', '', '', '', '200px', '250px', '']}
							marginLeft={['', '', '', '', '50px', '70px', '80px']}
						/>
					</Flex>
				) : null}
				<Flex
					flexDirection='column'
					width={['', '', '', '', '20%', '20%', '20%']}
					paddingTop={['10px', '', '', '', '', '', '0px', '']}>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='semibold'>
						REPUBLIC OF THE PHILIPPINES
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						All content is in the public domain unless otherwise stated.
					</Box>
				</Flex>
				<Flex
					flexDirection='column'
					width={['', '', '', '', '20%', '20%', '20%']}
					paddingTop={['10px', '', '', '', '', '', '0px', '']}>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='semibold'>
						ABOUT GOVPH
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Learn more about the Philippine government, its structure, how government works and the
						people behind it.
					</Box>
					<Box paddingTop={['10px', '', '', '', '', '', '0px', '']}></Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						GOV.PH
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Open Data Portal
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Official Gazette
					</Box>
				</Flex>
				<Flex
					flexDirection='column'
					width={['', '', '', '', '20%', '20%', '20%']}
					paddingTop={['10px', '', '', '', '', '', '0px', '']}
					marginRight={['10px']}>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='semibold'>
						GOVERNMENT LINKS
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Office of the President
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Office of Vice President
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Senate of the Philippines
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						House of Representatives
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Supreme Court
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Court of Appeals
					</Box>
					<Box fontSize={['10px', '', '', '', '10px', '14px', '16px']} fontWeight='light'>
						Sandiganbayan
					</Box>
				</Flex>
			</Flex>
		</>
	);
};
export default Footer;
